import { Header } from '@orus.eu/pharaoh/src/patterns/header'
import { useParams } from '@tanstack/react-router'
import { useCallback, useMemo, useState } from 'react'
import { trpcReact } from '../../../client'
import { NotFound } from '../../organisms/not-found'
import { RenewalFailState } from './RenewalFailState'
import { RenewalStartState, type RcdaFields } from './RenewalStartState'
import { RenewalSuccessState } from './RenewalSuccessState'

type RenewalState =
  | { type: 'start' }
  | { type: 'success'; isLocked: boolean }
  | { type: 'failed' }
  | { type: 'no-renewal' }

export const RenewalPage = function RenewalPage(): JSX.Element {
  const { token } = useParams({ from: '/s/r/$token' })

  const [renewalDetails] = trpcReact.renewal.receiveRenewal.useSuspenseQuery({ token })

  const validateRenewalAsClient = trpcReact.renewal.validateRcdaRenewalAsClient.useMutation()

  const initialState: RenewalState = useMemo(() => {
    if (renewalDetails.status === 'approved') {
      if (renewalDetails.isExcluded) {
        return { type: 'failed' }
      }
      if (renewalDetails.blockedStatus !== 'not-blocked') {
        return { type: 'success', isLocked: true }
      } else {
        return { type: 'success', isLocked: false }
      }
    } else if (renewalDetails.status === 'cancelled') {
      return { type: 'no-renewal' }
    } else if (renewalDetails.blockedStatus !== 'not-blocked') {
      return { type: 'success', isLocked: true }
    }
    return { type: 'start' }
  }, [renewalDetails.status, renewalDetails.isExcluded, renewalDetails.blockedStatus])

  const [state, setState] = useState<RenewalState>(initialState)

  const goToSuccessState = () => {
    setState({ type: 'success', isLocked: false })
  }

  const goToFailState = () => {
    setState({ type: 'failed' })
  }

  const goToStartState = () => {
    setState({ type: 'start' })
  }

  const validateRenewal = useCallback(
    (rcdaFields: RcdaFields) => {
      validateRenewalAsClient.mutate({
        token,
        rcdaFields,
      })
    },
    [validateRenewalAsClient, token],
  )

  return (
    <>
      <Header compact={true} />
      {state.type === 'start' ? (
        <RenewalStartState
          isRenewalValidationPending={validateRenewalAsClient.isPending}
          renewalValidationType={
            validateRenewalAsClient.isSuccess ? validateRenewalAsClient.data.type : 'not-validated'
          }
          renewalDetails={renewalDetails}
          validateRenewal={validateRenewal}
          goToSuccessState={goToSuccessState}
          goToFailState={goToFailState}
        />
      ) : undefined}
      {state.type === 'success' ? (
        <RenewalSuccessState
          isLocked={state.isLocked}
          renewalDetails={renewalDetails}
          goToStartState={goToStartState}
        />
      ) : undefined}
      {state.type === 'failed' ? <RenewalFailState /> : undefined}
      {state.type === 'no-renewal' ? <NotFound /> : undefined}
    </>
  )
}
